/**
 * Datadog action names to measure performance
 */

export const datadogActions = {
  EC_LOOKUP_API_CALL: "ec_lookup_api_call", // how long embedded component lookup API call takes
  EC_ACCEPT_CHALLENGE_API_CALL: "ec_accept_challenge_api_call", // how long accept challenge API call takes
  EC_ALL_CARDS_API_CALL: "ec_all_cards_api_call", // how long all cards API call takes

  EC_AUTH_COMPONENT_RENDER_FROM_SDK: "ec_auth_component_render_from_sdk", // time between sdk message to render auth UI
  EC_CAROUSEL_COMPONENT_RENDER_FROM_SDK: "ec_carousel_component_render_from_sdk", // time between sdk message to render auth UI or carousel UI

  EC_CAROUSEL_COMPONENT_RENDER_FROM_OTP: "ec_carousel_component_render_from_otp", // time between otp completion to carousel UI rendering

  ENCRYPT_API_CALL: "encrypt_api_call", // how long our /encrypt call takes
  DECRYPT_API_CALL: "decrypt_api_call", // how long our /decrypt call takes

  LOAD_CARDS: "load_cards", // time between completing OTP and fetching cards for the shopper (email, button, & paylinks)

  // CTP SDK Method Performance
  CTP_INIT: "ctp_init", // track CTP init() method duration
  CTP_ID_LOOKUP: "ctp_id_lookup", // track CTP idLookup() method duration
  CTP_INITIATE_VALIDATION: "ctp_initiate_validation", // track CTP initiateValidation() method duration
  CTP_VALIDATE: "ctp_validate", // track CTP validate() method duration
  CTP_ENCRYPT_CARD: "ctp_encrypt_card", // track CTP encryptCard() method duration
  CTP_CHECKOUT_WITH_CARD: "ctp_checkout_with_card", // track CTP checkoutWithCard() method duration
  CTP_CHECKOUT_WITH_NEW_CARD: "ctp_checkout_with_new_card", // track CTP checkoutWithNewCard() method duration

  // CTP User Flow Performance
  CTP_LINK: "ctp_link", // track FE to CL service /link/ctp response time
  CTP_LOOKUP_TO_OTP: "ctp_lookup_to_otp", // track email entry to OTP screen render
  CTP_OTP_TO_CARDS: "ctp_otp_to_cards", // track from finish entering OTP to cards selection screen render
  CTP_CARD_SELECTION_TO_REVIEW: "ctp_card_selection_to_review", // track card selection to review page render
};

/**
 * Event Names for performance.mark
 * This allow us to measure the time between two events cross files
 */
export const performanceMarkers = {
  EC_AUTH_COMPONENT_RECEIVED_MESSAGE: "ec_auth_component_received_message", // when auth component receives message from SDK
  EC_CAROUSEL_COMPONENT_RECEIVED_MESSAGE: "ec_carousel_component_received_message", // when carousel component receives message from SDK
  EC_AUTH_OTP_COMPLETED: "ec_auth_otp_completed", // when otp is successfully completed
  OTP_COMPLETED: "otp_completed", // when otp is successfully completed for checkout flows (email, button, & paylinks)
  CTP_EMAIL_LOOKUP_STARTED: "ctp_email_lookup_started", // when CTP email lookup is initiated on the email input
  CTP_OTP_CODE_ENTERED: "ctp_otp_code_entered", // when user enters OTP code
  CTP_CARD_CONTINUE_CLICKED: "ctp_card_continue_clicked", // when user clicks continue after selecting a card
};
