import fp from "./fingerprint";

import axios from "axios";
import { getPriceFromAdjustablePrice } from "@utils/orderUtils";
import { Unit } from "skipify-types";
import Dinero from "dinero.js";
import { FlowTypes, SKIPIFY_ANALYTICS_CONST } from "@constants/skipifyEvent";
import { Order } from "@models/order";
import { BaseEvent, EventPropertiesMap, EventType, IdentifyProperties, UserFingerprints } from "@models/skipifyEvents";
import { v4 as uuidv4 } from "uuid";
import { FingerprintJSPro } from "@fingerprintjs/fingerprintjs-pro-spa";
import getEnv from "@utils/getEnv";
import { TTLStorage } from "@utils/ttlStorage";

const toSnakeCase = (obj: FingerprintJSPro.ExtendedGetResult): UserFingerprints => {
  const subdivisions: {
    iso_code: string;
    name: string;
  }[] = [];
  if (obj.ipLocation?.subdivisions) {
    obj.ipLocation?.subdivisions.forEach((subdivision) => {
      subdivisions.push({
        iso_code: subdivision.isoCode,
        name: subdivision.name,
      });
    });
  }
  const fpVisitorData = {
    request_id: obj.requestId,
    browser_name: obj.browserName,
    browser_version: obj.browserVersion,
    device: obj.device,
    first_seen_at: {
      global: obj.firstSeenAt.global,
      subscription: obj.firstSeenAt.subscription,
    },
    incognito: obj.incognito,
    ip: obj.ip,
    ip_location: {
      accuracy_radius: obj.ipLocation?.accuracyRadius,
      city: {
        name: obj.ipLocation?.city?.name,
      },
      continent: {
        code: obj.ipLocation?.continent?.code,
        name: obj.ipLocation?.continent?.name,
      },
      country: {
        code: obj.ipLocation?.country?.code,
        name: obj.ipLocation?.country?.name,
      },
      latitude: obj.ipLocation?.latitude,
      longitude: obj.ipLocation?.longitude,
      postal_code: obj.ipLocation?.postalCode,
      subdivisions: subdivisions,
      timezone: obj.ipLocation?.timezone,
    },
    last_seen_at: {
      global: obj.lastSeenAt.global,
      subscription: obj.lastSeenAt.subscription,
    },
    os: obj.os,
    os_version: obj.osVersion,
    visitor_found: obj.visitorFound,
    visitor_id: obj.visitorId,
  };
  return fpVisitorData;
};

const formatPrices = (price?: Partial<Unit>) => {
  if (!price) return undefined;
  return Dinero({ amount: Number(price.value), currency: (price?.uom || "USD") as Dinero.Currency }).toRoundedUnit(2);
};

// Pull session ID from local storage or create a new one
const getSessionId = () => {
  let analyticsSessionId = Date.now().toString(10);
  const ttlStorage = new TTLStorage();
  if (typeof window !== "undefined") {
    const savedSessionId = ttlStorage.getItem<string>(SKIPIFY_ANALYTICS_CONST.LOCAL_STORAGE_KEY);
    analyticsSessionId = savedSessionId || analyticsSessionId;
    ttlStorage.setItem(SKIPIFY_ANALYTICS_CONST.LOCAL_STORAGE_KEY, analyticsSessionId, SKIPIFY_ANALYTICS_CONST.TTL);
  }
  return +analyticsSessionId;
};

export class Pubsub {
  flowType?: FlowTypes = undefined;
  issuer?: string = undefined;
  deviceId?: string = undefined;
  customerId?: string = undefined;
  // Order properties
  subtotal?: number;
  total?: number;
  isLoaded: boolean = false;
  sessionId?: number = getSessionId();
  fpVisitorData?: UserFingerprints = {};
  eventQueue: BaseEvent[] = [];
  maxBatchSize: number = Number(getEnv().SKIPIFY_EVENTS_BATCH_SIZE) || 5;
  batchingTimeout = 5000;
  timer: NodeJS.Timeout | null = null;
  identify_properties: IdentifyProperties = {};
  currentPage?: string = undefined;

  setFlowType(flow: FlowTypes) {
    this.flowType = flow;
  }

  setIssuer(issuer?: string) {
    this.issuer = issuer;
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  setMerchantProperties(merchantId?: string, merchantName?: string, industry?: string, parentMerchant?: string) {
    this.identify_properties.merchant_id = merchantId;
    this.identify_properties.merchant_name = merchantName;
    this.identify_properties.merchant_industry = industry;
    this.identify_properties.parent_merchant_name = parentMerchant;
  }

  getMerchantProperties() {
    return this.identify_properties;
  }

  setOrderProperties(order: Order) {
    const subtotal = formatPrices({
      value: getPriceFromAdjustablePrice(order?.pricing.subTotal, order?.pricing.subTotal.tax),
      uom: order?.pricing.subTotal.amount.uom,
    });
    const total = formatPrices(order?.pricing.total);

    if (subtotal) this.subtotal = subtotal;
    if (total) this.total = total;
  }

  async loadWithFingerprint() {
    // this function assumes that any overrides are set safely by the caller, and does not check to see if you're in a dev environment.
    try {
      const { visitorId } = await fp.getFingerprint();
      this.deviceId = visitorId;
    } catch (e) {
      console.warn("fingerprint client error on pubsub initialization", e);
    } finally {
      this.isLoaded = true;
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   */
  async identify(userId?: string, properties?: IdentifyProperties) {
    try {
      const fpProps = await fp.getExtendedFingerprintData();
      if (fpProps) {
        this.fpVisitorData = toSnakeCase(fpProps);
      }

      // Retain merchant-related properties
      const { merchant_id, merchant_name, merchant_industry, parent_merchant_name } = this.identify_properties;

      this.identify_properties = {
        merchant_id,
        merchant_name,
        merchant_industry,
        parent_merchant_name,
        userId: userId,
        ...properties,
      };

      if (this.currentPage) {
        this.identify_properties.page = this.currentPage;
      }

      this.track("identify", this.identify_properties);
    } catch (err) {
      console.error("Something went wrong with the identify event", err);
    }
  }

  setCurrentPage(pageName: string) {
    this.currentPage = pageName;
  }

  private getRequiredEventProps() {
    return {
      ...this.getMerchantProperties(),
      total: this.total,
      subtotal: this.subtotal,
      card_linking_issuer: this.issuer,
      flow_type: this.flowType,
      device_id: this.deviceId,
      customer_id: this.customerId,
      page: this.currentPage,
    };
  }

  async track<T extends EventType>(type: T, event_properties?: EventPropertiesMap[T]) {
    const referrer = document.referrer;
    let event: BaseEvent | IdentifyProperties = {
      event_type: type,
      service: "shakira-checkout",
      uuid: uuidv4(),
      event_time: Date.now(),
      session_id: this.sessionId,
      device_id: this.deviceId,
      merchant_id: this.getMerchantProperties().merchant_id,
      event_properties: {
        ...event_properties,
        ...this.getRequiredEventProps(),
      },
      fingerprint: this.fpVisitorData,
      referrer,
    };
    // Identify has different properties than the rest of events
    if (type === "identify") {
      event = {
        ...event,
        ...this.identify_properties,
      };
    }

    this.eventQueue.push(event);
    if (this.eventQueue.length >= this.maxBatchSize) {
      this.flush();
    } else {
      this.flushByInterval();
    }
  }

  async flush() {
    if (this.eventQueue.length === 0) {
      return;
    }

    const unQueueEventsToProcess = this.eventQueue.splice(0, this.maxBatchSize);

    try {
      await axios.post("/api/track-event", { events: unQueueEventsToProcess });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        console.error("Error posting events:", error.response?.data || error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    }
    this.timer = null;
  }

  flushByInterval() {
    if (!this.timer) {
      this.timer = setTimeout(() => this.flush(), this.batchingTimeout);
    }
  }

  trackLookupUser(email?: string, isPhoneRequired?: boolean) {
    this.identify(email);
    if (isPhoneRequired !== undefined) {
      isPhoneRequired ? this.track("fe_customer_not_recognized") : this.track("fe_customer_recognized");
    }
  }

  refresh() {
    if (this.identify_properties.userId) {
      this.identify_properties.userId = undefined;
      this.customerId = undefined;
    }
    this.sessionId = Date.now();
  }

  async onExit() {
    this.track("fe_checkout_exited");
    await this.flush();
  }
}
