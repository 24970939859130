import { CardActionArea, CardContent, CardMedia, CardProps, Stack, Typography, styled, Box } from "@mui/material";
import { MouseEvent } from "react";
import BaseCard, { CARD_HEIGHT } from "@components/CardDisplay/BaseCard";
import { DefaultCardArtDisplay } from "@components/CardDisplay/DefaultCardArtDisplay";
import { CARD_ART_HEIGHT, CARD_ART_WIDTH } from "@components/CardDisplay/constants";
import { MaskedCard } from "@models/ctp";
import CtpLogo from "@images/click_to_pay/ctp-logo-bw.png";
import Image from "next/image";
import AmexLogo from "@images/click_to_pay/logos/amex.svg";
import AmexLogoDark from "@images/click_to_pay/logos/amex-dark.svg";
import DiscoverLogo from "@images/click_to_pay/logos/discover.svg";
import DiscoverLogoDark from "@images/click_to_pay/logos/discover-dark.svg";
import MastercardLogo from "@images/click_to_pay/logos/mastercard.svg";
import MastercardLogoDark from "@images/click_to_pay/logos/mastercard-dark.svg";
import VisaLogo from "@images/click_to_pay/logos/visa.svg";
import VisaLogoDark from "@images/click_to_pay/logos/visa-dark.svg";
import CtpVerticalDivider from "@images/click_to_pay/ctp-vertical-divider.svg";

/**
 * Props for the payment method card component.
 */
type ClickToPayPaymentCardProps<V> = {
  id: string;
  card: MaskedCard;
  disabled?: boolean;
  value: V;
  onChange: (e: MouseEvent<HTMLButtonElement>, value: V) => void;
  selected: boolean;
} & Omit<CardProps, "onClick" | "onChange">;

export const getCardLogo = (paymentCardDescriptor?: string, dark = false) => {
  switch (paymentCardDescriptor?.toLowerCase()) {
    case "amex":
    case "american-express":
      return { logo: dark ? AmexLogoDark : AmexLogo, height: 12 };
    case "discover":
      return { logo: dark ? DiscoverLogoDark : DiscoverLogo, height: 12 };
    case "mastercard":
      return { logo: dark ? MastercardLogoDark : MastercardLogo, height: 14 };
    case "visa":
      return { logo: dark ? VisaLogoDark : VisaLogo, height: 9 };
    default:
      return { logo: null, height: 0 };
  }
};

const CardArt = ({ src }: { src?: string }) => {
  if (!src) {
    return <DefaultCardArtDisplay />;
  }

  return (
    <CardMedia
      image={src}
      sx={{
        height: CARD_ART_HEIGHT,
        width: CARD_ART_WIDTH,
        borderRadius: "6px",
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
    />
  );
};

const CardDisplay = ({ card, id, disabled }: { card: MaskedCard; id: string; disabled?: boolean }) => {
  const { digitalCardData, panLastFour } = card;
  const cardArtUri = digitalCardData?.artUri;
  const description = digitalCardData?.descriptorName;
  const nickname = digitalCardData?.presentationName;

  return (
    <CardContent
      sx={{
        p: 1.5,
        backgroundColor: disabled ? "secondary.light" : "inherit",
        opacity: disabled ? 0.5 : 1,
        width: "100%",
      }}
    >
      <Stack spacing={1} flexDirection={"column"}>
        <CardArt src={cardArtUri} />
        <Stack flexDirection={"column"}>
          <Typography id={id} variant="micro" fontWeight="500">
            ••••{panLastFour}
          </Typography>
          {nickname && (
            <Typography
              id={id}
              variant="micro"
              fontWeight="500"
              sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {nickname}
            </Typography>
          )}
          <Typography id={id} variant="micro" fontWeight="500">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  );
};

const BasePaymentMethodCard = styled(BaseCard)<CardProps & { selected?: boolean }>((props) => ({
  ...(props.selected && {
    boxShadow: `inset 0 0 0 2px ${props.theme.palette.primary.dark}`,
    borderColor: "transparent",
  }),
}));

const ClickToPayPaymentCard = <V,>(props: ClickToPayPaymentCardProps<V>) => {
  const { id, card, disabled = false, selected, onChange, value, ...cardProps } = props;

  const { logo, height } = getCardLogo(card.paymentCardDescriptor);

  return (
    <BasePaymentMethodCard
      selected={selected}
      sx={{
        backgroundColor: (theme) => (selected ? theme.palette.background.paper : theme.palette.secondary.light),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: `${CARD_HEIGHT}px`,
      }}
      {...cardProps}
    >
      <Box sx={{ flex: 1 }}>
        <CardActionArea
          aria-selected={selected}
          disabled={disabled}
          disableRipple
          disableTouchRipple
          onClick={(e) => {
            onChange?.(e, value);
          }}
          aria-label="Payment method"
          aria-describedby={id}
          sx={{
            "&:hover .MuiCardActionArea-focusHighlight": {
              opacity: 0,
            },
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
            "&.Mui-disabled": {
              border: "none",
            },
            display: "flex",
            alignItems: "flex-start",
            borderRadius: 0,
            height: "100%",
            font: "unset",
          }}
        >
          <CardDisplay id={id} card={card} disabled={disabled} />
        </CardActionArea>
      </Box>
      <Box
        sx={{
          px: 1.5,
          mb: 1.5,
          height: "15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ alignItems: "center" }}
          divider={<Image src={CtpVerticalDivider} alt="" height={12} priority />}
        >
          <Image src={CtpLogo} alt="Click to Pay" height={12} priority />
          {logo && <Image src={logo} alt={`${card.paymentCardDescriptor || "Card"} Logo`} height={height} priority />}
        </Stack>
      </Box>
    </BasePaymentMethodCard>
  );
};

export default ClickToPayPaymentCard;
